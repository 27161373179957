
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loading: { default: false },
  },
  setup(props) {
    return {};
  },
});
