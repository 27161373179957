/* eslint-disable @typescript-eslint/no-explicit-any */
import http from "@/http";

class BaseService {
  list(endpoint: string): Promise<any> {
    return http.get(`${endpoint}`);
  }

  store(endpoint: string, data: any): Promise<any> {
    return http.post(`${endpoint}`, data);
  }

  update(endpoint: string, id: string, data: any): Promise<any> {
    return http.post(`${endpoint}/${id}?_method=PUT`, data);
  }

  delete(endpoint: string, id: string): Promise<any> {
    return http.delete(`${endpoint}/${id}`);
  }

  status(endpoint: string, id: string): Promise<any> {
    return http.put(`${endpoint}/status/${id}`);
  }

  put(endpoint: string, data: any): Promise<any> {
    return http.put(`${endpoint}`, data);
  }
}

export default new BaseService();
