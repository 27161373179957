import { ref } from "vue";
import BaseService from "@/services/admin/BaseService";
import Notify from 'quasar/src/plugins/Notify.js';;

export default (endpoint: string, model: string, x: string, baseForm: any, emit: any, endpointStatus = "") => {
  const show = ref(false);
  const form = ref();
  const row = ref();
  const loading = ref<boolean>(false);

  const submit = (): void => {
    if (!row.value?.id) {
      loading.value = true;
      BaseService.store(endpoint, form.value)
        .then(() => {
          afterSubmit();
          Notify.create({
            type: "positive",
            message: `${capitalize(model)} salv${x} com sucesso`,
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          if (err.response.status == 422) {
            Object.entries(err.response.data.errors).forEach(([key, value]) => {
              const valueMessage: Array<string> = value as Array<string>;
              Notify.create({
                type: "negative",
                message: valueMessage[0],
              });
            });
          } else {
            Notify.create({
              type: "negative",
              message: err.response.data.message,
            });
          }
        })
        .finally(() => (loading.value = false));
    } else {
      loading.value = true;
      BaseService.update(endpoint, row.value.id, form.value)
        .then(() => {
          afterSubmit();
          Notify.create({
            type: "positive",
            message: `${capitalize(model)} salv${x} com sucesso`,
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          if (err.response.status == 422) {
            Object.entries(err.response.data.errors).forEach(([key, value]) => {
              const valueMessage: Array<string> = value as Array<string>;
              Notify.create({
                type: "negative",
                message: valueMessage[0],
              });
            });
          } else {
            Notify.create({
              type: "negative",
              message: err.response.data.message,
            });
          }
        })
        .finally(() => (loading.value = false));
    }
  };

  const status = (): void => {
    if (row.value) {
      loading.value = true;
      BaseService.status(endpointStatus, row.value.id)
        .then(() => {
          afterSubmit();
          Notify.create({
            type: "positive",
            message: `Status d${x} ${model} alterado com sucesso`,
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          Notify.create({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    }
  };

  const destroy = (): void => {
    if (row.value) {
      loading.value = true;
      BaseService.delete(endpoint, row.value.id)
        .then(() => {
          afterSubmit();
          Notify.create({
            type: "positive",
            message: `${capitalize(model)} excluíd${x} com sucesso`,
          });
        })
        .catch((err) => {
          console.error(err.response.data.message);
          Notify.create({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    }
  };

  const afterSubmit = (): void => {
    emit("update");
    show.value = false;
    form.value = baseForm;
  };

  const capitalize = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return {
    // Data
    show,
    form,
    row,
    loading,

    // Methods
    submit,
    status,
    destroy,
    afterSubmit,
  };
};
